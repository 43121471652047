import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers'
import {useMenu} from "./MenuContext";

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isActive?: boolean
}

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
                                                                children,
                                                                to,
                                                                title,
                                                                icon,
                                                                fontIcon,
                                                                hasBullet,
                                                                isActive = false,
                                                              }) => {
  const {pathname} = useLocation()
  if (isActive === undefined || !isActive) isActive = checkIsActive(pathname, to)
  // const isActive = checkIsActive(pathname, to)
  useEffect(() => {
    if (title === '통계 / 관제') {
      console.log('통계 / 관제 isActive', isActive)
    }
    if (!isActive) {
      // setIsSubmenuRendered(false);
      // setSubmenuRendered(false)
    }
  }, [isActive])

  const [, setIsSubmenuRendered] = useState(false);
  const {setSubmenuRendered} = useMenu();
  useEffect(() => {
    // 비동기 작업 수행 후 isSubmenuRedered 상태 업데이트
    setIsSubmenuRendered(true);
    setSubmenuRendered(true)
  }, []);

  if (children) {
    return (

      <div
        className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
        data-kt-menu-trigger='click'
      >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2'/>
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
        <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
          {children}
        </div>
      </div>
    )
  } else {
    return (
      <>
      </>
    )
  }
}

export {AsideMenuItemWithSub}
