import {useState, useContext, useEffect} from 'react'
import MapSVG from './MapSVG';


const Map = ({legendLeft, legendRight, legendJeju, currentLocation, setCurrentLocation}) => {


    const manageLocate = (code) => {

        if (code === currentLocation) {
            setCurrentLocation('')
        } else {
            setCurrentLocation(code)
        }
    }

    // if(!legendLeft || !legendRight || !legendJeju) {

    // 	console.log(legendLeft,legendRight,legendJeju)
    // 	return null
    // }


    return (
        <>
            <div className=' map-wrapper px-4   w-full h-5/6  sm:w-[600px] mb-[25px] overflow-hidden' style={{}}>
                <div className='relative rounded-xl shadow-xl p-4 w-full bg-white ' style={{height: '580px'}}>
                    <div className='absolute top-10'>
                        {(legendLeft || []).map(item => (
                            <div
                                className={`mb-8 shadow-xl rounded-md transition-all delay-150 ${currentLocation === item.cityCode ? 'scale-125' : ''} `}
                                key={item.cityCode}>
                                <div className={`
				grid grid-cols-2 text-white transition-all delay-150 ease-in-out 
				border-2 border-transparent rounded-t-md 
				h-[21px] pt-0.5
				${currentLocation === item.cityCode ? 'bg-indigo-400 ' : 'bg-slate-400 '}
				`}
                                    //  style={{background:currentLocation === item.cityCode  ? '#818cf8' :'#616161' , border:currentLocation === item.cityCode ? '1px solid #818cf8' :'1px solid #616161'}}
                                >
                                    <div className='text-center text-xs font-bold px-2 '>
                                        <div>
                                            급속
                                        </div>
                                    </div>
                                    <div className='text-center text-xs font-bold px-2'>
                                        <div>
                                            완속
                                        </div>
                                    </div>
                                </div>
                                <div className={`grid grid-cols-2  rounded-b-md border-1
				${currentLocation === item.cityCode ? ' border-indigo-500' : 'border-gray-100'}
				`}
                                    // style={{ border:currentLocation === item.cityCode ? '1px solid #818cf8' :'1px solid #616161'}}
                                >
                                    <div className='text-center '>
                                        <div>
                                            {item.fastCount}
                                        </div>
                                    </div>
                                    <div className='text-center '>
                                        <div>
                                            {item.slowCount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='absolute top-10 right-4 bg-white ' style={{zIndex: ''}}>
                        {(legendRight || []).map(item => (
                            <div
                                className={`mb-8 shadow-xl rounded-md transition-all delay-150 ${currentLocation === item.cityCode ? 'scale-125' : ''}`}
                                key={item.cityCode}>
                                <div className={`
			grid grid-cols-2 text-white transition-all delay-150 ease-in-out 
			border-2 border-transparent rounded-t-md 
			h-[21px] pt-0.5
			${currentLocation === item.cityCode ? 'bg-indigo-400 ' : 'bg-slate-400 '}
		`}
                                    // style={{background:currentLocation === item.cityCode  ? '#818cf8' :'#616161' , border:currentLocation === item.cityCode ? '1px solid #818cf8' :'1px solid #616161'}}

                                >
                                    <div className='text-center text-xs font-bold px-2 '>
                                        <div>
                                            급속
                                        </div>
                                    </div>
                                    <div className='text-center text-xs font-bold  px-2'>
                                        <div>
                                            완속
                                        </div>
                                    </div>
                                </div>
                                <div className={`
		grid grid-cols-2  rounded-b-md border-1
		${currentLocation === item.cityCode ? ' border-indigo-500' : 'border-gray-100'}
		`}>
                                    <div className='text-center  '>
                                        <div>
                                            {item.fastCount}
                                        </div>
                                    </div>
                                    <div className='text-center '>
                                        <div>
                                            {item.slowCount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                    <div className='absolute' style={{top: '500px', left: '240px'}}>
                        <div
                            className={`mb-8 shadow-xl rounded-md transition-all delay-150 ${currentLocation === legendJeju?.cityCode ? 'scale-125' : ''}`}>
                            <div className={`
	grid grid-cols-2  text-white transition-all delat-150 ease-in-out
	border-2 border-transparent rounded-t-md 
	h-[21px] pt-0.5
	${currentLocation === legendJeju?.cityCode ? 'bg-indigo-400 ' : 'bg-slate-400 '}
	`}

                                // style={{background:currentLocation === legendJeju?.cityCode  ? '#818cf8' :'#616161' , border:currentLocation === legendJeju?.cityCode ? '1px solid #818cf8' :'1px solid #616161'}}
                            >
                                <div className='text-center text-xs font-bold  px-2'>
                                    <div>
                                        급속
                                    </div>
                                </div>
                                <div className='text-center text-xs font-bold  px-2'>
                                    <div>
                                        완속
                                    </div>
                                </div>
                            </div>
                            <div className={`
	grid grid-cols-2  rounded-b-md border-1
	${currentLocation === legendJeju?.cityCode ? ' border-indigo-500' : 'border-gray-100'}
	`}

                            >
                                <div className='text-center px-2 text-black'>
                                    <div>
                                        {legendJeju?.fastCount}
                                    </div>
                                </div>
                                <div className='text-center px-2 text-black'>
                                    <div>
                                        {legendJeju?.slowCount}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="-mt-40" style={{transform: 'scale(0.4)'}}>
                        <MapSVG
                            currentLocation={currentLocation}
                            setCurrentLocation={manageLocate}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Map;
