/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from "../../../../app/modules/auth";
import {useLocation} from "react-router-dom";

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const location = useLocation();

  const payHistoryPath = 'pay_history';
  const isPayHistoryActive = location.pathname.includes(payHistoryPath);
  // useEffect(() => {
  //   setForceUpdate(prev => prev + 1);
  // }, [location])


  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator me-4 '
      />

      {/*<AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>*/}
      {/*  <AsideMenuItem*/}
      {/*    to='/crafted/pages/wizards/horizontal'*/}
      {/*    title='Horizontal'*/}
      {/*    hasBullet={true}*/}
      {/*  />*/}
      {/*  <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}
      <AsideMenuItemWithSub
        to='/ocpp/pages/station'
        title='인프라 관리'
        fontIcon='bi bi-ev-station-fill me-4'
      >
        <AsideMenuItem to='/ocpp/pages/station/station' title='충전소 관리' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/station/charger' title='충전기 관리' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/station/monitor' title='충전기 관리 - 모니터' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/station/remote' title='충전기 관리 - 원격제어' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/station/price' title='충전 단가 관리' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/station/model' title='충전기 모델 관리' hasBullet={true}/>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/ocpp/pages/operation' title='운영 관리'
                            fontIcon='bi bi-ev-front-fill  me-4 '>
        {/*<AsideMenuItem to='/ocpp/pages/operation/cp_status' title='충전기 상태' hasBullet={true} />*/}
        <AsideMenuItem to='/ocpp/pages/operation/trouble_manage' title='고장 관리' hasBullet={true}/>
        {/*<AsideMenuItem to='/ocpp/pages/operation/cp_control' title='충전기 제어' hasBullet={true} />*/}
        <AsideMenuItem to='/ocpp/pages/operation/cp_map' title='설치 위치' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/operation/cp_firmware' title='펌웨어 관리' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/operation/upload' title='업로드 파일 관리' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/operation/member_card' title='회원 카드관리' hasBullet={true}/>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/ocpp/pages/statistics' title='통계 / 관제'
                            fontIcon='bi bi-graph-up me-4' isActive={isPayHistoryActive}>
        {/*<AsideMenuItem to='/ocpp/pages/statistics/pay_history' title='충전 이력' hasBullet={true} isActive={isPayHistoryActive}/>*/}
        <AsideMenuItem to='/ocpp/pages/statistics/pay_history' title='충전 이력' hasBullet={true}/>

        <AsideMenuItem to='/ocpp/pages/statistics/statistic_daily' title='일별 충전 이력' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/statistics/statistic_monthly' title='월별 충전 이력' hasBullet={true}/>

        <AsideMenuItem to='/ocpp/pages/statistics/me_pay_history' title='로밍 결제 이력' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/statistics/guest_pay_history' title='비회원 결제 이력' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/statistics/member_pay_history' title='자사 회원 결제 이력' hasBullet={true}/>
        {/*<AsideMenuItem to='/ocpp/pages/statistics/ocpp_comm_log' title='Ocpp 통신 로그' hasBullet={true} />*/}
        <AsideMenuItem to='/ocpp/pages/statistics/charger_trouble_history' title='충전기 장애 이력' hasBullet={true}/>
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/ocpp/pages/common_management' title='공통 관리' hasBullet={true}>
        <AsideMenuItem to='/ocpp/pages/common_management/code' title='코드 관리' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/common_management/company' title='회사 관리' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/common_management/menu' title='메뉴 관리' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/common_management/permission' title='권한 관리' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/common_management/user' title='사용자 관리' hasBullet={true}/>
        <AsideMenuItem to='/ocpp/pages/common_management/me_card' title='로밍카드 관리' hasBullet={true}/>
      </AsideMenuItemWithSub>

      {/*<AsideMenuItemWithSub to='/ocpp/pages/file_management' title='파일 관리' hasBullet={true}>*/}
      {/*  <AsideMenuItem to='/ocpp/pages/file_management/file' title='파일 관리' hasBullet={true}/>*/}
      {/*</AsideMenuItemWithSub>*/}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          {/*<span className='menu-section text-muted text-uppercase fs-8 ls-1'>OCPP</span>*/}
        </div>
      </div>
      <div>{currentUser?.userId}</div>
      {currentUser?.managerLevel === 3 && (
        <AsideMenuItem
          to='/ocpp/ocpp-log/ocpplogs'
          icon='black-right'
          title='통신 기록'
          fontIcon='bi-layers'
        />
      )}
    </>
  )
}
