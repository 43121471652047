import {useEffect, useState} from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const labels = [
    "서울시",
    "부산시",
    "대구시",
    "인천시",
    "광주시",
    "대전시",
    "울산시",
    "경기도",
    "강원도",
    "충청남도",
    "전라북도",
    "전라남도",
    "경상북도",
    "경상남도",
    "제주도"
];



const optionsA = {
    responsive         : true,
    maintainAspectRatio: false,
    scales             : {
        x: {
            grid     : {
                display: false,
                color  : '#818cf8',
            },
            ticks    : {
                color: '#64748b',
            },
            gridLines: {
                display: false
            },
            border   : {
                display: false
            },
        },
        y: {
            grid  : {
                drawBorder: false,
                lineWidth : 0,
                display   : false,
                color     : '#818cf8',
            },
            ticks : {
                color   : '#000',
                stepSize: 500
            },
            border: {
                display: false
            },

        },
    },

    plugins: {
        legend: {
            labels  : {
                color: 'rgb(255, 99, 132)'
            },
            position: 'top',
        },

    },

}


// const options = {
// responsive: true,
// plugins: {
//   legend: {
// 	position: 'top' ,
//   },
//   title: {
// 	display: false,
// 	text: 'Chart.js Bar Chart',
//   },
// },
//  };


export default function Table({data}) {
    const [chartData, setChartData] = useState({
        labels  : [],
        datasets: []
    });



    useEffect(() => {
        if (data) {
            console.log('data received', data);
            const labels = data.lebel.map(item => item);
            const fastCounts = data.datasets[0].data.map(item => parseInt(item, 10));
            const slowCounts = data.datasets[1].data.map(item => parseInt(item, 10));

            const datasets = [
                {
                    label: '급속',
                    data: fastCounts,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: '완속',
                    data: slowCounts,
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                }
            ];

            setChartData({ labels, datasets });
        }
    }, [data]);


    useEffect(() => {
        console.log('updated chartData', chartData);
    }, [chartData]);

    if (!data) {
        return null
    }

    return (
        <div className=' map-wrapper px-4   w-full h-5/6  sm:w-[600px]' style={{}}>
            <div className='relative rounded-xl shadow-xl p-4 w-full bg-white ' style={{height: '280px'}}>
                <Bar options={optionsA} data={chartData}/>
            </div>
        </div>
    )
}
