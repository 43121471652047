import React, { createContext, useState, useContext, ReactNode } from 'react';

interface MenuContextValue {
  isSubmenuRendered: boolean;
  setSubmenuRendered: (rendered: boolean) => void;
}

// 기본 컨텍스트 값 정의
const defaultContextValue: MenuContextValue = {
  isSubmenuRendered: false,
  setSubmenuRendered: () => {}
};


const MenuContext = createContext<MenuContextValue>(defaultContextValue);

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider');
  }
  return context;
};

// props 타입 정의
type MenuProviderProps = {
  children: ReactNode;
};

export const MenuProvider: React.FC<MenuProviderProps> = ({ children }) => {
  const [isSubmenuRendered, setSubmenuRendered] = useState(false);

  // const triggerReload = () => {
  //   setSubmenuRendered(prev => !prev);
  // };

  return (
    <MenuContext.Provider value={{ isSubmenuRendered, setSubmenuRendered }}>
      {children}
    </MenuContext.Provider>
  );
};
