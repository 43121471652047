import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
  } from 'chart.js';
  import { Chart } from 'react-chartjs-2';

  import { faker } from '@faker-js/faker';

  ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController
  );
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


  const data = {
	labels,
	datasets: [
	  {
		type: 'line' ,
		label: '데이터 1',
		borderColor: 'rgb(75, 192, 192)',
		backgroundColor: 'rgb(75, 192, 192)',
		borderWidth: 3,
		tension: 0.1,
		fill: false,
		data: labels.map(() => faker.number.int({ min: 0, max: 1000 })),
	  },
	  {
		type: 'bar' ,
		label: '데이터 2',
		backgroundColor: '#818cf8',
		data: labels.map(() => faker.number.int({ min: 0, max: 1000 })),
		borderWidth: 1,
		borderRadius: 30,
		borderColor: '#818cf8',
		//  barPercentage: 0.5,
        barThickness: 15,

	  },
	],
  };





const MixedChart = ({chartData}) => {



	const options = {
		responsive: true,
		maintainAspectRatio:false,
		scales: {
			x: {
			  grid: {
				display: false,
				color:'#818cf8',
			  },
			  ticks: {
				color:'#64748b',
			  },
			  gridLines: {
                display: false
            },
			border:{
				display:false
			  },
			},
			y: {
			  grid: {
				drawBorder: false,
				lineWidth: 0,
				display: false,
				color:'#818cf8',
			  },
			  ticks: {
				color:'#000',
				stepSize:500
			  },
			  border:{
				display:false
			  },

			},
		  },

        plugins: {
            legend: {
                display: true,
                labels: {
                    color: 'rgb(255, 99, 132)'
                },
				position : 'top',
            },

			tooltip: {
				enabled: false // <-- this option disables tooltips
			  }
        },

    }


	if(!chartData) {
		return null;
	}


	return (
		<>
			{/*<div className=' w-full  border-2 rounded-xl px-4 py-2 shadow-xl '>*/}
			{/*<div style={{ position: "relative", margin: "auto", width: "100%" }}>*/}
					<Chart type='bar' data={data} options={options} className='w-full h-full '/>
			{/*</div>*/}
			{/*</div>*/}
		</>
	)
}
export default MixedChart;
