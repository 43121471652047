import dayjs from "dayjs";

const people = [
    {name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member'},
]

const tableData = [
    {name: '경기도', value1: '삼보테크노', value2: '406호 충전기', value3: '3.4KW', value4: '1700원', value5: '01:20.12'},
    {name: '인천', value1: '인천테크노', value2: '407호 충전기', value3: '3.4KW', value4: '2700원', value5: '02:20.12'},
]
const chartData = {
    labels         : ['업무용', '공용', '아파트', '전기버스'],
    data           : [300, 50, 100, 70],
    backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)',
        'rgb(54, 162, 86)',
    ],
    unit           : '개소'
}

export default function Table({data}) {

    return (
        <div className="relative bg-white  shadow-xl px-8 pt-4 pb-9 rounded-xl w-full sm:w-[600px] h-[279px] ">
            <div className='mb-1'>
                <div className=''>
                    <div className='text-md font-bold tracking-tight  text-slate-700 inline-block align-middle'>
                        일자별 충전량
                    </div>
                    <div className='inline-block cursor-pointer'>
                        {/*<ArrowUpRightIcon className='w-3 inline align-middle ml-2 '/>*/}
                    </div>
                </div>
            </div>
            {/* <div className=' absolute left-1 -ml-1 top-0  px-0.5 py-0.5 border border-black bg-white cursor-pointer' >
			<PlusIcon className='w-2'/>
		</div> */}
            <div className="mt-4 flow-root h-[230px] overflow-auto">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8  ">
                        <table className=" divide-y  divide-gray-100 min-w-full">
                            <thead>
                            <tr className="">
                                <th scope="col"
                                    className="py-3 px-4 pr-3 text-left text-sm font-semibold text-slate-500   text-center">
                                    일자
                                </th>
                                <th scope="col" className="px-3 py-1 text-left text-sm font-semibold text-slate-500  text-center">
                                    충전량(kWh)
                                </th>
                                <th scope="col" className="px-3 py-1 text-left text-sm font-semibold text-slate-500  text-center">
                                    충전 횟수
                                </th>
                                <th scope="col" className="px-3 py-1 text-left text-sm font-semibold text-slate-500  text-center">
                                    매출액(원)
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                            {data.map((item, i) => (
                                <tr key={i} className=" ">
                                    <td className="px-3  whitespace-nowrap  py-2 text-md text-gray-700 font-bold  text-center">
                                        {dayjs(item.chargingDate).format('YYYY-MM-DD')}
                                    </td>
                                    <td className="px-3 whitespace-nowrap  py-2 text-md text-gray-700 font-semibold  text-center">
                                        {Number(item.totalKwh).toLocaleString()}
                                    </td>
                                    <td className="px-3 whitespace-nowrap  py-2 text-md text-slate-600 font-semibold text-center">
                                        {Number(item.totalCount).toLocaleString()}
                                    </td>
                                    <td className="px-3 whitespace-nowrap  py-2 text-md text-slate-600 font-semibold text-center">
                                        {Number(item.totalAmount).toLocaleString()}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
