import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = () => {
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const OcppLogPage = lazy(() => import('../modules/ocpp/ocpp-log/OcppLogPage'))
    const StationPage = lazy(() => import('../modules/station/StationPage'))
    const OperationPage = lazy(() => import('../modules/operation/OperationPage'))
    const StatisticsPage = lazy(() => import('../modules/statistics/StatisticsPage'))
    const CommonManagementPage = lazy(() => import('../modules/common_management/CommonManagementPage'))
    const FileManagementPage = lazy(() => import('../modules/file_management/FileManagementPage'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper />} />
                {/* Lazy Modules */}

                <Route
                    path='crafted/pages/wizards/*'
                    element={
                        <SuspensedView>
                            <WizardsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='ocpp/ocpp-log/*'
                    element={
                        <SuspensedView>
                            <OcppLogPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='ocpp/pages/station/*'
                    element={
                        <SuspensedView>
                            <StationPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='ocpp/pages/operation/*'
                    element={
                        <SuspensedView>
                            <OperationPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='ocpp/pages/statistics/*'
                    element={
                        <SuspensedView>
                            <StatisticsPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='ocpp/pages/common_management/*'
                    element={
                        <SuspensedView>
                            <CommonManagementPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path='ocpp/pages/file_management/*'
                    element={
                        <SuspensedView>
                            <FileManagementPage />
                        </SuspensedView>
                    }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}


const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
