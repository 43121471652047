import {FC, useEffect} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers'
import {useMenu} from "./MenuContext";

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isActive?: boolean
}

const AsideMenuItem: FC<Props & WithChildren> = ({
                                                   children,
                                                   to,
                                                   title,
                                                   icon,
                                                   fontIcon,
                                                   hasBullet = false,
                                                   isActive = false,
                                                 }) => {
  const {pathname} = useLocation()
  const {isSubmenuRendered} = useMenu();
  if (isActive === undefined || !isActive) isActive = checkIsActive(pathname, to)
  // const isActive = checkIsActive(pathname, to)
  useEffect(() => {
    if (title === '충전 이력') {
      console.log('충전 이력 isActive', isActive)
    }
  }, [isActive])

  useEffect(() => {
    console.log("isRender 충전 이력")
  }, []);

  if (isSubmenuRendered) {
    return (
      <div className='menu-item'>
        <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
          {hasBullet && (
            <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
          )}
          {icon && (
            <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2'/>
          </span>
          )}
          {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
          <span className='menu-title'>{title}</span>
        </Link>
        {children}
      </div>
    )
  } else {
    return (
      <>
      </>
    )
  }
}

export {AsideMenuItem}
